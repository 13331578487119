<template>
  <div style="width: 100%" class="fill-height">
    <v-card tile color="grey" style="width: 100%;height: 48px"></v-card>
    <div class="fill-height d-flex flex-wrap justify-center">
      <div style="width: 100%" class="d-flex justify-center">
        <lottie-animation loop :animation-data="require('@/assets/loading.json')"></lottie-animation>
      </div>
      <div class="pa-4">
        <h1>{{ $t('Bitte warten') }} ⏰</h1>
        <p>{{ $t('Ihr wunderbares Bestellerlebnis steht vor der Tür') }}</p>
        <p>Made with ❤️ &nbsp;@InnerKen</p>
      </div>
    </div>

  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'
import {getCategoryWithDishes} from "@/dataLayer/service/api"; // import lottie-web-vue


export default {
  components: {LottieAnimation},
  name: "LoadingPage",
  async mounted() {
    await getCategoryWithDishes('DE')
    this.$router.push('/menu')
  }
}
</script>

<style scoped>

</style>
