<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <div style="width: 100%;position: relative;background-color: #367aeb;">
        <div class="container" style="position: relative">
          <v-btn @click="$router.back()"
                 class="ml-n6"
                 text color="white">
            <v-icon left>mdi-arrow-left</v-icon>
            {{ $t('Back') }}
          </v-btn>
        </div>

      </div>
      <div
          style="max-width: 1200px;width: 100vw;display: grid;grid-template-columns: 53.5% 39.5%;padding-bottom: 150px;grid-gap: 2.5%"
          class="mx-auto">
        <div>
          <div>
            <div class="contentPadding">
              <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Lieferung Info') }}</div>
            </div>
            <v-expansion-panels class="mb-1">
              <v-expansion-panel>
                <div @click="showAddressSelector = true">
                  <v-expansion-panel-header>
                    <template>
                      <div class="d-flex align-center">
                        <div>
                          <v-icon class="mr-2" v-if="isPickUp">mdi-walk</v-icon>
                          <v-icon class="mr-2" v-else>mdi-truck-fast</v-icon>
                          {{ $t('deliveryMethod') }}
                        </div>
                        <v-spacer></v-spacer>

                        <div
                            class="text--secondary"
                        >
                          <template v-if="addressInfo">
                            <template>
                              <div>{{ $t(deliveryMethod) }}</div>
                              <div style="font-size: 10px;letter-spacing: -0.2px">
                                {{
                                  isPickUp ? addressInfo.firstName + ' ' + addressInfo.lastName : addressInfo.addressLine1
                                }}
                              </div>
                            </template>
                          </template>
                        </div>


                      </div>


                    </template>
                  </v-expansion-panel-header>
                </div>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <template v-slot:default="{ open }">
                    <div class="d-flex align-center">
                      <v-icon class="mr-2">mdi-comment-text-outline</v-icon>
                      {{ $t('Anmerkung') }}
                      <v-spacer>
                      </v-spacer>
                      <span
                          v-if="!open"
                          key="0"
                      >
                            <span v-if="note" class="dishDesc mt-2">{{ note }}</span>
                          </span>
                      <span
                          v-else
                          key="1"
                      >
                          </span>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-textarea v-model="commentStr" hide-details no-resize
                              :placeholder="$t('Schreibe eine Anmerkung (wie besondere Anfragen, Allergien, Ernährungseinschränkungen usw.)...')"></v-textarea>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="contentPadding mt-8">
              <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Zahlungsdetails') }}</div>
            </div>

            <v-expansion-panels v-model="paymentPanel" multiple focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div>
                    <div>
                      <v-icon>mdi-credit-card-outline</v-icon>
                      {{ $t('Paymentmethode') }}
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-4">
                  <v-radio-group v-model="payMethodId">
                    <v-radio @click="showPaymentArea=false" v-for="payment in paymentList" :key="payment.id"
                             :label="payment.name"
                             :value="payment.id"></v-radio>
                  </v-radio-group>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div class="contentPadding mt-8">
              <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Pick Time / Delievery Time') }}</div>
            </div>
            <v-expansion-panels v-model="timeSelectPanel" multiple focusable>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <div class="d-flex align-center" style="width: 100%">
                    <div>
                      <v-icon class="mr-4">mdi-calendar-month-outline</v-icon>
                      {{ $t('Pick Time / Delievery Time') }}
                    </div>
                    <v-spacer/>
                    <div>{{ $t(futureWeek[selectedDay].text) }}
                      <span v-if="selectedTime">, {{ selectedTime }}</span>
                    </div>
                  </div>

                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-4">
                  <template>
                    <div style="padding: 0 16px">
                      <div>
                        <v-select v-model="selectedDay" :items="futureWeek" item-text="text"
                                  item-value="value"></v-select>
                        <v-select v-model="selectedTime" return-object :items="showTime"
                                  :no-data-text="$t('noDataAvailable')"></v-select>
                      </div>
                    </div>
                  </template>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <template>
              <template v-if="canOrder">
                <template v-if="activePayMethodIsPaypal">
                  <PayPal
                      style="position: fixed;bottom: 16px; width: 90vw; left :5vw"
                      v-if="activePayMethodIsPaypal"
                      @payment-authorized="()=>sendOrder(true)"
                      @payment-complete="()=>sendOrder(true)"
                      currency="EUR"
                      :amount="totalPrice.toFixed(2)"
                      :client="paypalCredentials"
                      :button-style="{
              size:'responsive'
            }"
                  />
                </template>
                <template v-else>
                  <v-btn @click="()=>sendOrder()" :loading="loading" dark x-large class="mainButton checkOutPageButton">
                    <v-icon left>mdi-shopping-outline</v-icon>
                    <span
                        style="font-size: 14px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">{{
                        $t('Order Senden')
                      }}</span>
                    <v-spacer></v-spacer>
                    <div>{{ totalPrice | priceDisplay }} €</div>
                  </v-btn>
                </template>
              </template>
              <v-btn v-else-if="!payMethodId" dark color="#46474d" class="checkOutPageButton">
                <div>
                  <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
                    {{ $t('Bitte Zahlungsmethode wählen.') }}
                  </div>
                </div>
              </v-btn>
              <v-btn v-else-if="differentPrice < 0" dark color="#46474d" class="checkOutPageButton">
                <div>
                  <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
                    {{ $t('Bitte noch') }} {{ -differentPrice | priceDisplay }} € {{ $t('toReachStartingDeliveryFee') }}
                  </div>
                </div>
              </v-btn>

              <v-btn disabled v-else-if="!selectedTime" class="checkOutPageButton">
                <div>
                  <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
                    {{ $t('plsSelectReservationTime') }}
                  </div>
                </div>
              </v-btn>
              <v-btn v-else @click="showAddressSelector=true" dark color="#46474d" class="checkOutPageButton">
                <div>
                  <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
                    {{ $t('plsEditAddress') }}
                  </div>
                  <div class="mt-2"
                       style="font-size: 12px !important;text-transform: capitalize;font-weight: 300;letter-spacing: 0">
                    {{ $t('Klicken, um die Bestellsdetails zu ändern') }}
                  </div>
                </div>

              </v-btn>
            </template>
          </div>
        </div>

        <div>
          <div class="d-flex align-center pa-4">
            <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Gewählte Gericht') }}</div>
            <v-spacer></v-spacer>
            <v-btn text @click="cart.clear()"> {{ $t("clear") }}
              <v-icon color="red">mdi-delete</v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div style="max-height: 350px; overflow-y: scroll;">
            <edit-dish-card @click="currentDish=dish;" :image-root="GlobalConfig.imageRoot" v-for="dish in cartList"
                            :key="dish.id" :dish="dish"
                            :in-cart="true"></edit-dish-card>
          </div>
          <div v-ripple @click="$router.push('/')" class="addMoreItem d-flex contentPadding align-center">
            <v-icon>mdi-plus</v-icon>
            <div class="ml-3">
              {{ $t('Mehr gerichte hintufügen') }}
            </div>
          </div>
          <div class="contentPadding mt-8">
            <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Preise sind inkl.MwSt.') }}</div>
          </div>
          <div class="contentPadding d-flex justify-space-between" style="font-size: 10px;">
            <span>{{ $t('Gerichte insgesamt') }}</span><span>{{ totalPriceWithoutMod | priceDisplay }} €</span>
          </div>
          <div v-if="discount>0" class="contentPadding d-flex justify-space-between" style="font-size: 10px;">
            <span>{{ $t('discount') }}</span><span>- {{ discount | priceDisplay }} €</span>
          </div>
          <div v-if="deliveryCost > 0 && !canArriveFreePrice" class="contentPadding d-flex justify-space-between"
               style="font-size: 10px;">
            <span>{{ $t('Liefergebühr') }}</span><span>{{ deliveryCost | priceDisplay }} €</span>
          </div>
          <div v-if="deliveryCost > 0 && canArriveFreePrice" class="contentPadding d-flex justify-space-between"
               style="font-size: 10px;">
            <span>{{ $t('Liefergebühr') }}</span>
            <div> {{ $t("freeDeliveryFee") }}<span class="ml-2" style=" text-decoration: line-through;">{{
                deliveryCost | priceDisplay
              }} €</span></div>
          </div>
          <div class="contentPadding d-flex justify-space-between align-center" style="font-size: 10px;">
            <span style="font-weight: 600;font-size: 0.7rem;">
              {{ $t('Gesamtbetrag') }}
            </span>
            <span style="font-weight: bold;font-size: 1.5rem">{{ totalPrice | priceDisplay }} €</span>
          </div>
          <div style="height: 160px;width: 100%"></div>
        </div>

      </div>
    </div>
    <div v-else>
      <div class="pt-10 d-flex flex-column align-start"
           style="width: 100%;height: 240px;position: relative;background-color: #367aeb;">
        <div class="container">
          <div style="color: white;text-align: left">
            <div style="font-size: xx-large;font-weight: 900;
                font-family: 'Work Sans', sans-serif;">{{ $t('toReception') }}
            </div>
            <div style="font-size: medium;font-weight: 400;
                font-family: 'Work Sans', sans-serif">
              {{ restaurantInfo.name }}
            </div>
          </div>

        </div>
        <v-spacer/>
        <v-btn @click="$router.back()"
               text color="white">
          <v-icon left>mdi-arrow-left</v-icon>
          <span class="ml-1">{{ $t('back') }}</span>
        </v-btn>

      </div>
      <div class="container">
        <div>
          <div v-ripple @click="showAddressSelector=true" class="d-flex contentPadding align-center">
            <v-icon v-if="isPickUp">mdi-walk</v-icon>
            <v-icon v-else>mdi-truck-fast</v-icon>
            <div class="ml-3">
              <template v-if="addressInfo==null">
                {{ $t('Liefermethode und Zeit') }}
              </template>
              <template v-else>
                <template>
                  <div>{{ $t(deliveryMethod) }}</div>
                  <div
                      style="font-size: 10px;letter-spacing: -0.2px"
                  >
                    {{
                      isPickUp ? addressInfo.firstName + ' ' + addressInfo.lastName :
                          addressInfo.addressLine1 + '@' + addressInfo.firstName + ' ' +
                          addressInfo.lastName
                    }}
                  </div>
                </template>
              </template>
            </div>
            <v-spacer></v-spacer>
            <v-icon>mdi-chevron-right</v-icon>
          </div>
          <v-divider></v-divider>
        </div>
        <div class="contentPadding mt-8">
          <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Gewählte Gericht') }}</div>
        </div>
        <v-divider></v-divider>
        <edit-dish-card :image-root="GlobalConfig.imageRoot" v-for="dish in cartList" :key="dish.id" :dish="dish"
                        :in-cart="true"></edit-dish-card>
        <div v-ripple @click="$router.push('/')" class="d-flex contentPadding align-center">
          <v-icon>mdi-plus</v-icon>
          <div class="ml-3">
            {{ $t('Mehr gerichte hintufügen') }}
          </div>
        </div>

        <v-divider></v-divider>
        <div @click="showCommentArea=!showCommentArea;note=commentStr"
             class="d-flex contentPadding align-center">
          <v-icon>mdi-comment-text-outline</v-icon>
          <div class="ml-3">
            <div>{{ $t('Anmerkung für das Restaurant') }}</div>
            <div v-if="note" class="dishDesc mt-2">{{ note }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div v-if="showCommentArea" class="pa-4" style="background: #f6f6f6">
          <v-textarea v-model="commentStr" hide-details height="100"
                      :placeholder="$t('Schreibe eine Anmerkung (wie besondere Anfragen, Allergien, Ernährungseinschränkungen usw.)...')"></v-textarea>
        </div>
        <v-divider></v-divider>

        <div class="contentPadding mt-8">
          <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Pick Time / Delievery Time') }}</div>
        </div>
        <v-divider></v-divider>
        <div @click="showDateArea=!showDateArea" class="d-flex contentPadding align-center">
          <v-icon>mdi-calendar-month-outline</v-icon>
          <div class="ml-3">
            <div>
              {{ $t('Pick Time / Delievery Time') }}
            </div>
            <div style="font-size: 10px;letter-spacing: -0.2px;">
              {{ futureWeek[selectedDay].text }}

              <span v-if="selectedTime"> , {{ selectedTime }} </span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div v-if="showDateArea" class="px-4">
          <div>
            <v-select v-model="selectedDay" :items="futureWeek" item-text="text" item-value="value"></v-select>
            <v-select v-model="selectedTime" return-object :items="showTime"></v-select>
          </div>
        </div>
        <v-divider></v-divider>

        <div class="contentPadding mt-8">
          <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Pick Time / Delievery Time') }}</div>
        </div>
        <v-divider></v-divider>
        <div @click="showPaymentArea=!showPaymentArea" class="d-flex contentPadding align-center">
          <v-icon>mdi-credit-card-outline</v-icon>
          <div class="ml-3">
            <div>
              {{ $t('Paymentmethode') }}
            </div>
            <div style="font-size: 10px;letter-spacing: -0.2px;">
              {{ selectedPayMethod.name }}
            </div>
          </div>
          <v-spacer></v-spacer>
          <v-icon>mdi-chevron-right</v-icon>
        </div>
        <div v-if="showPaymentArea" class="px-4">
          <v-radio-group v-model="payMethodId" column>
            <v-radio @click="showPaymentArea=false" v-for="payment in paymentList" :key="payment.id"
                     :label="payment.name"
                     :value="payment.id"></v-radio>
          </v-radio-group>
        </div>
        <v-divider></v-divider>


        <div class="contentPadding mt-8">
          <div style="font-weight: bold;font-size: 0.9rem">{{ $t('Preise sind inkl.MwSt.') }}</div>
        </div>
        <div class="contentPadding d-flex justify-space-between" style="font-size: 10px;">
          <span>{{ $t('Gerichte insgesamt') }}</span><span>{{ totalPriceWithoutMod | priceDisplay }} €</span>
        </div>
        <div v-if="discount>0" class="contentPadding d-flex justify-space-between" style="font-size: 10px;">
          <span>{{ $t('discount') }}</span><span>- {{ discount | priceDisplay }} €</span>
        </div>
        <div v-if="deliveryCost > 0 && !canArriveFreePrice" class="contentPadding d-flex justify-space-between"
             style="font-size: 10px;">
          <span>{{ $t('Liefergebühr') }}</span><span>{{ deliveryCost | priceDisplay }} €</span>
        </div>
        <div v-if="deliveryCost > 0 && canArriveFreePrice" class="contentPadding d-flex justify-space-between"
             style="font-size: 10px;">
          <span>{{ $t('Liefergebühr') }}</span>
          <div> {{ $t("freeDeliveryFee") }}<span class="ml-2" style=" text-decoration: line-through;">{{
              deliveryCost | priceDisplay
            }} €</span></div>
        </div>
        <div class="contentPadding d-flex justify-space-between align-center" style="font-size: 10px;">
          <span style="font-weight: 600;font-size: 0.7rem;">{{ $t('Gesamtbetrag') }}</span><span
            style="font-weight: bold;font-size: 1.5rem">{{ totalPrice | priceDisplay }} €</span>
        </div>
        <div style="height: 160px;width: 100%"></div>
        <template v-if="canOrder">
          <template v-if="activePayMethodIsPaypal">
            <PayPal
                style="position: fixed;bottom: 16px; width: 90vw; left :5vw"
                v-if="activePayMethodIsPaypal"
                @payment-authorized="()=>sendOrder(true)"
                @payment-complete="()=>sendOrder(true)"
                currency="EUR"
                :amount="totalPrice.toFixed(2)"
                :client="paypalCredentials"
                :button-style="{
              size:'responsive'
            }"
            />
          </template>
          <template v-else>
            <v-btn @click="()=>sendOrder()" :loading="loading" v-if="canOrder" dark x-large
                   class="mainButton checkOutPageButton">
              <v-icon left>mdi-shopping-outline</v-icon>
              <span
                  style="font-size: 14px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">{{
                  $t('Order Senden')
                }}</span>
              <v-spacer></v-spacer>
              <div>{{ totalPrice | priceDisplay }} €</div>
            </v-btn>
          </template>
        </template>
        <v-btn v-else-if="!payMethodId" disabled class="mainButton checkOutPageButton">
          <div>
            <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
              {{ $t('Bitte Zahlungsmethode wählen.') }}
            </div>
          </div>
        </v-btn>

        <v-btn v-else-if="differentPrice < 0" dark class=" checkOutPageButton">
          <div>
            <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
              {{ $t('Bitte noch') }} {{ -differentPrice | priceDisplay }} € {{ $t('toReachStartingDeliveryFee') }}
            </div>
          </div>
        </v-btn>

        <v-btn v-else-if="!selectedTime" disabled class=" checkOutPageButton">
          <div>
            <div style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
              {{ $t('plsSelectReservationTime') }}
            </div>
          </div>
        </v-btn>
        <v-btn @click="showAddressSelector=true" v-else dark class=" checkOutPageButton">
          <div>
            <div
                style="font-size: 16px !important;text-transform: capitalize;font-weight: 500;letter-spacing: 0">
              {{ $t('plsEditAddress') }}
            </div>
            <div class="mt-2"
                 style="font-size: 12px !important;text-transform: capitalize;font-weight: 300;letter-spacing: 0">
              {{ $t('Klicken, um die Bestellsdetails zu ändern') }}
            </div>

          </div>
        </v-btn>
      </div>

    </div>
    <address-selector
        @save="save"
        v-model="showAddressSelector"
    />
  </div>

</template>

<script>
import CartService from '@/dataLayer/service/CartService'
import { defaultRestaurantInfo, getCurrentRestaurantInfo } from '@/dataLayer/repository/restaurantInfo'
import { calculateDiscountPrice } from 'aaden-base-model/lib/Models/AadenBase'
import EditDishCard from "@/components/EditDishCard"
import AddressSelector from '@/pages/widget/adress/AddressSelector'
import { DeliveryMethod, submitOrder } from '@/dataLayer/service/OrderService'
import { getFutureWeek, getOpeningTimeForWeekDay, getRestaurantInfo } from '@/dataLayer/service/api'
import PayPal from 'vue-paypal-checkout'
import GlobalConfig from "@/dataLayer/repository/GlobalSettingManager"
import IKUtils from "innerken-js-utils"
import dayjs from "dayjs"
import { OrderInfo } from '@/dataLayer/repository/currentOrderState'
import { ContactAddress } from '@/dataLayer/repository/localAddressInfo'

const version = require('../../package.json').version
export default {
  name: "CheckoutPage",
  components: {AddressSelector, PayPal, EditDishCard},
  data: function () {
    return {
      version,
      targetDay: 0,
      startPrice: 0,
      freePrice: 0,
      showDateArea: true,
      selectedDay: 0,
      futureWeek: getFutureWeek(),
      showTime: [],
      selectedTime: null,
      currentDish: null,
      paymentPanel: [0],
      timeSelectPanel: [0],
      showCommentArea: false,
      showPaymentArea: true,
      showDishDialog: false,
      paymentList: [],
      payMethodId: null,
      commentStr: '',
      cart: CartService,
      restaurantInfo: defaultRestaurantInfo,
      deliveryCost: 0,
      discountStr: '',
      showAddressSelector: false,
      note: '',
      deliveryMethod: null,
      addressInfo: null,
      DeliveryMethod,
      GlobalConfig,
      loading: false
    }
  },
  watch: {
    commentStr (val) {
      this.note = val
    },
    selectedDay: {
      immediate: true,
      async handler (val) {

        const targetWeekday = this.getTargetDay(val)
        this.targetDay = targetWeekday
        this.showTime = []
        this.showTime.push(...(await getOpeningTimeForWeekDay(targetWeekday)))

        this.selectedTime = null

      }
    }
  },
  computed: {
    differentPrice: function () {
      return this.totalPriceWithoutMod - this.startPrice
    },
    canArriveFreePrice: function () {
      return parseFloat(this.freePrice) !== 0 && (this.totalPriceWithoutMod - this.freePrice) > 0
    },
    dishCount: function () {
      return this.cartList.length > 0 ? this.cart.count() : 0
    },
    cartList: function () {
      return this.cart.list?.length > 0 ? this.cart.list.filter(i => i.count > 0) : []
    },
    totalPriceWithoutMod: function () {
      return this.cartList.length > 0 ? this.cart.total() : 0
    },
    totalPriceBeforeDiscount: function () {
      if (this.canArriveFreePrice) {
        return parseFloat(this.totalPriceWithoutMod)
      } else {
        return parseFloat(this.deliveryCost) + parseFloat(this.totalPriceWithoutMod)
      }
    },
    discount: function () {
      if (this.discountStr && this.isPickUp) {
        return calculateDiscountPrice(this.totalPriceWithoutMod, this.discountStr)
      } else {
        return 0
      }
    },
    isPickUp: function () {
      return this.deliveryMethod === DeliveryMethod.pickup
    },
    canOrder: function () {
      return this.dishCount > 0 &&
          this.addressInfo &&
          (this.isPickUp || (this.differentPrice >= 0)) &&
          this.payMethodId
          && this.selectedTime

    },
    totalPrice: function () {
      return this.totalPriceBeforeDiscount - parseFloat(this.discount)
    },
    selectedPayMethod: function () {
      return this.paymentList.find(p => p.id === this.payMethodId) ?? {name: 'Nichts'}
    },
    activePayMethodIsPaypal: function () {
      return this.selectedPayMethod.name.toLowerCase().includes('paypal')
    },
    paypalCredentials: function () {
      const paypal = this.paymentList.find(p => p.name.toLowerCase().includes('paypal'))
      return paypal ? {
        sandbox: 'AbBmj6XYaYu5X42wLdIrUMYmBUWTknOO3ikhMA_OihWFBJe-D4g-AGEG-kho6ASwvEv4bNIF57XC1TeR',
        production: paypal.apiKey
      } : {}
    }
  },
  methods: {
    getTargetDay: function (originDay) {
      let targetWeekday = dayjs().add(originDay, 'd').day()
      if (targetWeekday === 0) {
        targetWeekday = 7
      }
      return targetWeekday
    },
    save (addressInfo) {
      OrderInfo.address = addressInfo
      OrderInfo.deliveryRule = addressInfo.deliveryRule
      OrderInfo.deliveryMethod = addressInfo.deliveryMethod


      this.updateAllInfo()
      this.showAddressSelector = false
    },
    updateAllInfo () {
      this.addressInfo = OrderInfo.address
      this.deliveryMethod = OrderInfo.deliveryMethod
      this.deliveryCost = 0
      this.freePrice = 0
      this.startPrice = 0
      if (OrderInfo.deliveryRule) {
        const rule = OrderInfo.deliveryRule
        console.log(rule)
        if (rule.priceMod) {
          this.deliveryCost = rule.priceMod
        }
        this.startPrice = rule.startPrice
        this.freePrice = rule.freePrice
      }
      const isPickUp = OrderInfo.deliveryMethod === DeliveryMethod.pickup
      if (isPickUp) {
        this.discountStr = GlobalConfig.pickDiscountStr
      } else {
        this.discountStr = ""
      }
    },

    async sendOrder (paid = false) {
      this.loading = true
      const targetInfo = new ContactAddress(this.addressInfo)
      targetInfo.note = this.note.replaceAll(`\n`, '<BR>')
          .replaceAll("'", '-')
      targetInfo.date = dayjs().add(this.selectedDay, 'd').format('YYYY-MM-DD')
      targetInfo.time = this.selectedTime
      if (this.addressInfo.addressLine1) {
        targetInfo.addressLine1 = this.addressInfo.addressLine1.replaceAll("'", '-')
      }
      if (this.addressInfo.addressLine2) {
        targetInfo.addressLine2 = this.addressInfo.addressLine2.replaceAll("'", '-')
      }
      targetInfo.metaData = {
        takeawayWebVersion: 'new',
        version,
        paid
      }
      try {
        const res = await submitOrder(this.cartList, Object.assign({}, targetInfo, {
          deliveryMethod: this.isPickUp ? DeliveryMethod.pickup : DeliveryMethod.delivery,
          discountStr: this.discountStr,
          payMethodId: this.payMethodId,
          deliveryCost: this.canArriveFreePrice ? 0 : this.deliveryCost,
          paid

        }), this.totalPriceBeforeDiscount, paid)
        console.log(res, 'Submit')
        this.cart.clear()
        await this.$router.push('/complete')
      } catch (e) {
        IKUtils.showError(e, 'Please Contact Us 01749371328')
        console.log(e)
      }
      this.loading = false

    }
  },
  async mounted () {
    this.targetDay = this.getTargetDay(this.selectedDay)

    this.restaurantInfo = await getCurrentRestaurantInfo()
    this.paymentList = (await getRestaurantInfo(this.$i18n.locale, 'payMethod')).filter(p => parseInt(p.isOnline) === 1)
    this.paymentList.forEach(item => {
      item.name = item.langs.name
    })
    this.updateAllInfo()
    if (this.dishCount === 0) {
      this.$router.push('/menu')
    }


  }
}
</script>

<style scoped>

</style>
