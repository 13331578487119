import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import i18n from './i18n'
import { loadConfig } from '@/dataLayer/repository/GlobalSettingManager'

Vue.config.productionTip = false

Vue.filter('priceDisplay',
  function (price) {
    return parseFloat(price).toFixed(2).replace('.', ',')
  }
)

async function init(){
  await loadConfig()
  new Vue({
    vuetify,
    router,
    i18n,
    render: h => h(App)
  }).$mount('#app')
}

init()

