
const en = require('./locales/en.json')
const de = require('./locales/de.json')
const zh = require('./locales/zh.json')
// const pt = require('../locales/pt.json')

const supported = ['en', 'de', 'zh']
let locale = 'de'

try {
  // get browser default language
  const {0: browserLang} = navigator.language.split('-')

  if (supported.includes(browserLang)) {
    locale = localStorage.getItem('locale') ?? browserLang
  }
} catch (e) {
  console.log(e)
}


export default {
  // current locale
  locale,

  // when translation is not available fallback to that locale
  fallbackLocale: 'en',

  // availabled locales for user selection
  availableLocales: [
    {
      code: 'de',
      flag: 'de',
      label: 'Deutsch',
      messages: de
    }, {
      code: 'zh',
      flag: 'cn',
      label: '中文',
      messages: zh
    }, {
      code: 'en',
      flag: 'us',
      label: 'English',
      messages: en
    },
    {
      code: 'pt',
      flag: 'pt',
      label: 'Português',
      messages: de
    }

    ]
}

