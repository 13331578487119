<template>
  <v-bottom-sheet scrollable inset v-model="realShow">
    <v-card color="white" class="pa-4" style="position: relative;padding-bottom: 72px;overflow-y: scroll">
      <div style="font-size: xx-large;font-weight: 900;font-family: 'Work Sans', sans-serif;line-height: 100%">
        {{ restaurantInfo.name }}
      </div>
      <div class="d-flex align-center mt-2" style="font-size: small">
        <v-icon large :color="isOpen?'success':'error'">mdi-circle-small</v-icon>
        <span>{{ todayWeekday }} {{ todayOpenTimeDisplay }}</span>
      </div>
      <blockquote class="mt-4">{{ restaurantInfo.buffetAnnouncementHead }}</blockquote>
      <div class="d-flex align-center mt-4" style="font-size: small">
        <p>{{ restaurantInfo.buffetAnnouncementBody }}</p>
      </div>
      <h3>{{ $t('Adresse') }}</h3>
      <div class="mt-2" style="font-size: small">
        <p>{{ restaurantInfo.adress1 }}<br>
          {{ restaurantInfo.postCode }} {{ restaurantInfo.city }}</p>
      </div>
      <h3>{{ $t('Contact') }}</h3>
      <div class="mt-2" style="font-size: small">
        <p>{{ $t('Phone Number:') }} {{ restaurantInfo.telephone }}</p>
        <p>{{ $t('Email:') }} {{ restaurantInfo.emailAddress }}</p>
      </div>
      <h3>{{ $t('openingTimes') }}</h3>
      <div class="mt-2" style="font-size: small">
        <div v-for="(item, index) in openingTimeList" :key="index" class="d-flex mt-1 justify-space-between">
          <div class="font-weight-bold">
            {{ item.weekday }}
          </div>
          <div class="text-right">
            <span v-for="(timeItemForAll, n) in item.openTime" :key="n">
                {{ timeItemForAll.join(' - ') }}<br>
            </span>
          </div>
        </div>
      </div>
      <v-btn @click="cancel" style="position: absolute;right: 16px;top:16px;z-index: 5;background: white" icon>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card>

  </v-bottom-sheet>
</template>

<script>

import dayjs from "dayjs";

export default {
  name: "RestaurantDetailPage",
  data: function () {
    return {
      todayWeekday: dayjs().format('dddd')
    };
  },
  props: {show: {}, restaurantInfo: {}, openingTimeList: {}, todayOpenTimeDisplay: {}, isOpen: {}},
  computed: {
    realShow: {
      get: function () {
        return this.show
      },
      set: function () {
        this.cancel()
      }
    }
  },
  methods: {
    cancel() {
      this.$emit('close')
    },
  },
  async mounted() {
  }

}
</script>

<style scoped>

</style>
