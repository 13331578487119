<template>
  <div>
    <v-icon small>mdi-moped</v-icon>
    <div style="text-align: left" class="ml-2">
      <div style="font-size: xx-small;line-height: 9px">Aaden</div>
      <div style="font-size: small;font-weight: bold;line-height: 16px">Knoten v{{ version }}</div>
    </div>
  </div>
</template>

<script>
const version = require('../../../package.json').version
export default {
  name: "VersionInfo",
  data: function () {
    return {
      version: version
    }
  }
}
</script>

<style scoped>

</style>
