import hillo from 'hillo'
import GlobalConfig from '@/dataLayer/repository/GlobalSettingManager'

export const DeliveryMethod = {
  pickup: 'Zum Mitnehmen',
  delivery: 'Lieferung'
}

export const AsSoonAsPossible = "ASAP"

export function mergeObject (prototype, info) {
  const neededKeys = Object.keys(prototype)
  return Object.entries(info).reduce((obj, i) => {
    if (neededKeys.includes(i[0])) {
      obj[i[0]] = i[1]
    }
    return obj
  }, {})
}

export const DeliveryOrderInfo = {
  firstName: '',
  lastName: '',
  plz: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  email: '',
  tel: '',
  date: '',
  time: '',
  priceMod: 0,
  startPrice: 0,
  freePrice: 0,
  canDelivery: false,
  isPickUp: false
}

export const PickUpOrderInfo = {
  priceMod: 0,
  firstName: '',
  lastName: '',
  email: '',
  tel: '',
  date: '',
  time: '',
  isPickUp: true
}

export const DefaultOrderInfo = {
  firstName: '',
  lastName: '',
  plz: '',
  addressLine1: '',
  deliveryMethod: '',
  discountStr: '',
  city: '',
  email: '',
  tel: '',
  date: '',
  time: '',
  note: '',
  deliveryCost: '',
  payMethodId: ''
}

export async function submitOrder (orderList, addressInfo, totalPrice, paid = false) {

  const paramData = {
    order: orderList,
    addressInfo: addressInfo,
    paymentState: paid ? 1 : 0
  }
  return await hillo.jsonPost(GlobalConfig.cloudRoot + '/orders/create', {
    jsonRequest: JSON.stringify(paramData),
    dataTerminalId: GlobalConfig.dataTerminalId,
    totalPrice,
    paymentState: paid ? 1 : 0
  })

}
