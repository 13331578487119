import { loadBaseConfig } from 'aaden-base-model/lib/Models/GlobalSettings'
import { showRecords } from '@/plugins/fire-base'

const defaultConfig = require('@/assets/config.json')
let GlobalConfig = Object.assign({}, defaultConfig)
window.Config = GlobalConfig


export async function loadConfig () {
  const shortName = location.hostname.split('.')[0]
  const records = (await showRecords()).find(s => s.subdomainName === shortName)
  GlobalConfig = Object.assign(GlobalConfig, await loadBaseConfig(defaultConfig))
  console.log(records, '记录')

  if (records) {
    GlobalConfig.dataTerminalId = records.dataTerminalId
    GlobalConfig.deviceId = records.deviceId
    GlobalConfig.shopHomepageUrl = records.shopHomepageUrl
    GlobalConfig.hideDelivery = records?.hideDelivery === 'true' ?? false
    GlobalConfig.shopHomepageUrl = records.shopHomepageUrl ? 'http://' + records.shopHomepageUrl : "http://www.aaden-pos.com/en"

    if (records.bannerImagePath) {
      GlobalConfig.bannerPath = records.bannerImagePath
    }
  }

  GlobalConfig.imageRoot = getImgRoot()
}

export function getImgRoot () {
  return "http://IK" + GlobalConfig.deviceId.toString().padStart(4, '0') + '.ngrok.aaden.io' + GlobalConfig.imgRoot
}


export default GlobalConfig
